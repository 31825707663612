//
// HOME
//

export const HOME_SET_GEOJSON = 'HOME_SET_GEOJSON';

export const HOME_SET_LISTMAPS = 'HOME_SET_LISTMAPS';

export const HOME_SET_SINGLEPLAYER = 'HOME_SET_SINGLEPLAYER';
export const HOME_SET_MULTIPLAYER = 'HOME_SET_MULTIPLAYER';

//
// GAME
//
export const GAME_SET_COUNTRIES = 'GAME_SET_COUNTRIES';
export const GAME_SET_LOADING_COUNTRIES = 'GAME_SET_LOADING_COUNTRIES';
